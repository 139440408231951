<template>
  <table
    class="table tablesorter table-hover"
    :class="tableClass"
    aria-describedby="description"
  >
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th scope="col" v-for="column in columns" :key="column">
            {{ column }}
          </th>
        </slot>
      </tr>
    </thead>
    <tbody v-if="data.length > 0" :class="tbodyClasses">
      <tr
        v-for="(item, index) in data"
        :key="index"
        @click="handleClick(item.id)"
        :class="{ trHover: avaibleClick }"
        data-testid="row"
      >
        <slot :row="item">
          <td
            v-for="(column, index) in columns"
            :key="index"
            :hidden="!hasValue(item, column)"
          >
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
    <tbody v-if="data.length === 0">
      <tr v-if="data.length === 0" class="">
        {{
          $t("emptyTableText")
        }}
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "base-table",
  props: {
    avaibleClick: {
      type: Boolean,
      default: true,
    },
    tableClass: {
      type: String,
      default: "",
    },
    theadClasses: {
      type: String,
      default: "",
    },
    tbodyClasses: {
      type: String,
      default: "",
    },
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    columnsSize: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    handleClick(id) {
      if (id != null) {
        this.$emit("clickRow", id);
      }
      return;
    },
  },
};
</script>
<style>
.trHover:hover {
  cursor: pointer !important;
}
td {
  width: 100px;
  max-width: 100px;
}
.mw-lg {
  max-width: 200px !important;
}
.mw-md {
  max-width: 150px !important;
}
.mw-sm {
  max-width: 100px !important;
}
</style>
