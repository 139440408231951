<template class="white-content">
  <div id="app">
    <div class="content" v-if="isCheckAuthenticate">
      <notifications></notifications>
      <router-view :key="$route.fullPath"></router-view>

      <modal :show="isOpenModalLeavePage" :showClose="false">
        <template slot="header">
          <h5 class="modal-title">{{ $t("confirm.leavePage") }}</h5>
        </template>
        <template slot="">
          <div class="d-block d-md-flex justify-content-md-between">
            <base-button type="danger" variant="danger" class="d-block" @click="leavePage(false)">
              {{$t("cancel")}}
            </base-button>
            <base-button class="ml-1" type="secondary" @click="leavePage(true)">
              {{$t("confirm.continue")}}
            </base-button>
          </div>
        </template>
      </modal>
    </div>
    <div v-else class="container-spinner">
      <img
        loading="lazy"
        src="@/assets/img/Logo_AS_Transparent.png"
        alt="Logo AS Saloon"
      />
      <Spinner classes="m-auto" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Modal, Spinner } from "@/components/index";
import useLogout from "./shared/Auth/useLogout";
import updateMixin from './mixins/update';

export default {
  data() {
    return {
      isCheckAuthenticate: false,
    }
  },
  mixins: [updateMixin],
  metaInfo: {
    meta: [
      {
        property: "og:image",
        content: "/img/icons/apple-touch-icon-152x152.png",
      },
      {
        property: "twitter:image:src",
        content: "/img/icons/apple-touch-icon-152x152.png",
      },
    ],
  },
  methods: {
    async leavePage(isConfirm){
      this.$store.dispatch('setIsOpenModal', false);
      this.$store.dispatch('setHasChangeForm', false);
      if(isConfirm){
        if (this.nextPage === "/login") {
          await useLogout()
        }
        await this.$router.push(this.nextPage);
      }
    },
    async checkAuthenticate() {
      const apiToken = localStorage.getItem('api_token');
      const tokenExpiresIn = localStorage.getItem('token_expires_in')
      const isAuth = Boolean(apiToken);
      const isTokenExpired = tokenExpiresIn && new Date(tokenExpiresIn) < new Date();
  
      if(isTokenExpired) {
        await this.logout();
        return;
      }

      if(isAuth) {
        await this.refreshToken(apiToken);
      }
      
      this.isCheckAuthenticate = true;
    },
    async refreshToken(apiToken) {
      const response = await this.axios.post('/auth/refresh', { jwt_token: apiToken});
      
      if(response?.data?.status === "success") {
        const { jwt, user } = response.data?.data;
        const { token, expires_in } = jwt;
        const { role } = user;
        const expirationDate = new Date(
          new Date().getTime() + expires_in * 1000
        );
        
        this.axios.defaults.headers.common = await { Authorization: `Bearer ${token}` };
        localStorage.setItem("api_token", token);
        localStorage.setItem("token_expires_in", expirationDate);
        localStorage.setItem("userRole", role);
        this.$store.dispatch("setAuthenticate", token);
        this.$store.dispatch("setRole", role);
        if (this.isAdmin) {
          this.$router.push({ name: "userList" });
        } else if (this.isManager) {
          this.$router.push({ name: "bookings" });
        } else if (!this.isCustomer) {
          this.$router.push({ name: "bookings" });
        }
      } else {
          await this.logout()
      }

      this.isCheckAuthenticate = true;
    },
    async logout() {
      const response = await this.axios.get('/auth/logout');

      if(response?.data?.status === "success") {
        localStorage.removeItem("user");
        localStorage.removeItem("api_token");
        localStorage.removeItem("token_expires_in");
        localStorage.removeItem("userRole");
        localStorage.removeItem("profilePicture");
        this.$toast.error(this.$t("notifications.error.unauthorized"));
       await  this.$router.push({name: "login"});
      }

      this.isCheckAuthenticate = true;
    },
    ...mapActions(["setIsOpenModal"]),
  },
  async mounted() {
    this.checkAuthenticate();
  },
  computed:{
    ...mapState(["isOpenModalLeavePage", "nextPage", "isCustomer", "isAdmin", "isManager"]),

  },
  components: {
    Modal,
    Spinner
  }
};
</script>

<style scoped lang="scss">
@import '/assets/scss/white-dashboard.scss';
.adverting {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0;
  z-index: 1050;
}
.toggleAdverting {
  width: 60px;
  height: 30px;
  border-radius: 0.5rem 0.5rem 0 0;
  cursor: pointer;
}

.advertingIcon {
  font-size: 1.2rem;
  font-weight: bold;
}

.container-spinner {
  background: $primary-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;

  img {
    margin: 0 auto;
    width: 150px;
    height: 150px;
    filter: drop-shadow(1px 1px 5px #33333370);
    margin-bottom: 10px;
  }
}
</style>

<style>
.btn .spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
</style>