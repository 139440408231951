<template>
  <div
    class="form-group"
    :class="{
      'input-group-focus': focused,
    }"
  >
    <slot name="label">
      <label v-if="label" class="control-label">
        {{ label }}
      </label>
    </slot>
    <slot>
      <textarea
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        :maxlength="maxlength"
        :minlength="minlength"
        class="form-control"
        :class="classes"
        aria-describedby="addon-right addon-left"
      ></textarea>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-textarea",
  props: {
    label: String,
    value: [String, Number],
    addonRightIcon: String,
    addonLeftIcon: String,
    classes: String,
    maxlength: Number,
    minlength: Number
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  methods: {
    onInput(evt) {
      this.$emit("input", evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
};
</script>
<style scoped>
.input-rounded {
  border-radius: 1.5rem;
}
.iconLogin {
  padding: 10px 15px!important;
}
</style>
