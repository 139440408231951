<template>
  <div class="d-flex justify-content-center align-items-center" :class="parentClasses">
    <div class="spinner-border" :class="classes" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    classes: {
      type: String,
      default: ''
    },
    hidden: {
      type: Boolean,
      default: false
    },
    parentClasses: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '2rem'
    },
    height: {
      type: String,
      default: '2rem'
    },
    borderSize: {
      type: String,
      default: '0.25em' 
    }
  },
};
</script>

<style scoped>
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {  
  display: inline-block;
  width: v-bind(width);
  height: v-bind(height);
  vertical-align: text-bottom;
  border: v-bind(borderSize) solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.sr-only {
  display: none;
}
</style>
