<template>
  <a
    :class="classes"
    :href="
      isAuthenticated
        ? isManager || isEmployee
          ? bookings
          : isAdmin
          ? users
          : home
        : home
    "
  >
    <div class="photo">
      <img
        loading="lazy"
        src="@/assets/img/Logo_AS_Transparent.png"
        alt="Logo AS Saloon"
        width="30px"
        height="30px"
      />
    </div>
  </a>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Logo",
  data() {
    return {
      home: "/",
      bookings: "/bookings",
      users: "/users",
    };
  },
  props: {
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState([
      "isAuthenticated",
      "isAdmin",
      "isManager",
      "isEmployee",
      "isCustomer",
    ]),
  },
};
</script>

<style scoped>
.photo {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}
</style>
