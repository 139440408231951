import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//styles
import "@/assets/scss/white-dashboard.scss";
import "@/assets/css/nucleo-icons.css";
import "@/assets/demo/demo.css";
import "vue-cal/dist/vuecal.css";

// others
import GlobalComponents from "./plugins/globalComponents";
import GlobalDirectives from "./plugins/globalDirectives";
import Notify from "@/components/white-dashboard/NotificationPlugin";
import SideBar from "@/components/white-dashboard/SidebarPlugin";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

//plugins
import "./plugins/element.js";
import i18n from "./i18n";
import VueAxios from "vue-axios";
import Toast from "vue-toastification";
import "./assets/css/vue-toastification.css";
import axios from "./plugins/axios.js";
import VueMeta from "vue-meta";

import { DateTime, Settings } from 'luxon';
Settings.defaultZoneName = 'UTC';
Settings.defaultLocale = process.env.VUE_APP_MOMENT_LOCALE;
// EChart
import ECharts from 'vue-echarts';

//Sentry
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import "../public/registerServiceWorker";


//Google tag manager
import VueGtm from '@gtm-support/vue2-gtm';

// Vue Signature Pad
import VueSignaturePad from 'vue-signature-pad';


if(process.env.NODE_ENV !== "development"){
  
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_URL,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  });
}

Vue.config.productionTip = false;
Vue.prototype.$version = require('../package.json').version;

const toastOptions = { containerClassName: "ct-notification",
//Remove toast duplicates
filterBeforeCreate: (toast, toasts) => {
	if (toasts.filter(t => t.type === toast.type).length !== 0) {
		return false;
	}
	return toast;
}
};

Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(SideBar);
Vue.use(Toast, toastOptions);
Vue.use(Notify);
Vue.use(VueAxios, axios);
Vue.use(VueMeta);
Vue.use(VueTypeaheadBootstrap);
Vue.component("v-chart", ECharts);

Vue.use(VueSignaturePad);
Vue.config.productionTip = false;

if(process.env.NODE_ENV === "production") {

	Vue.use(VueGtm, {
	  id: 'GTM-NVMWX2D', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
	  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
	  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
	  debug: true, // Whether or not display console logs debugs (optional)
	  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
	  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
	  ignoredViews: ["dashboard", "bookings", "userList", "newUser", "manageUser", "customerList", "newCustomer", "customersManagement", "storeManagement", "employeeList",
	  				 "newEmployee", "employeeManagement", "servicesList", "servicesManage", "servicesNew", "servicesCategoryNew", "servicesCategoryManage", "categoryList",
	  				 "newCategory", "manageCategory"], // Don't trigger events for specified router names (case insensitive) (optional)
	  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
	});

}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount("#app");
