<template>
    <div class="back-button-container" :class="{'glued': scrollY >= scrollToSticky}" @click="$router.go(-1)">
        <img src="../assets/img/arrow_left.svg" :alt="$t('alt.prev')" width="30rem" height="30rem">
        <span class="my-auto mr-3">{{ $t('goBack') }}</span>
    </div>
</template>
  
<script>
export default {
    name: "backButton",
    props: {
        classes: {
            type: String,
            default: '',
        },
        top: {
            type: String,
            default: "0rem"
        },
        scrollToSticky: {
            type: Number,
            default: 0
        },
    },
    data: () => {
        return {
            scrollY: null
        }
    },
    methods: {
        handleScroll() {
            this.scrollY = scrollY;
        },
    },
    created() {
        addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        removeEventListener("scroll", this.handleScroll);
    },
};
</script>
  
<style scoped lang="scss">
.back-button-container {
    position: absolute;
    background: white;
    z-index: 999;
    padding: 0.5rem;
    margin-top: 1rem;
    width: 30%;
    margin-left: 1rem;
    border-radius: 3rem;
    border: 1px solid lightgray;
    box-shadow: 1px 1px 6px rgba(109, 109, 109, 0.4);
    cursor: pointer;
    transition: width 0.5s ease-in-out;
}
.glued {
    top: v-bind(top);
    position: fixed;
    width: 93%!important;
}
@media (min-width: 576px) {
    .back-button-container {
        width: 20%
    }
    .glued {
        width: 40%!important;
    }
}
@media (min-width: 768px) {
    .back-button-container {
        width: 8%;
    }
    .glued {
        width: 40%;
    }
    .back-button-container {
        width: 8%
    }
    .glued {
        width: 20%;
    }
}
@media (min-width: 768px) {
    .back-button-container {
        width: 120px!important;
    }
    .glued {
        width: 120px!important;
    }
}
</style>