<template>
  <div>
    <div class="row">
      <div v-for="(day, index) in days" :key="index" class="col border rounded text-center p-0 pb-1">
        <h5 class="border-bottom p-2">{{ $tc(`dayOfWeek.${day}`, 1) }}</h5>
        <div v-for="schedule in formatSchedules[day]" :key="schedule.key" class="border-bottom p-2 m-1 schedule">
          <div @click="openScheduleToEdit(schedule)">
            {{ schedule.start + "-" + schedule.end }}
          </div>
        </div>
        <div class="p-1">
          <base-button block type="secondary" @click="showModal('modal-datetime'); schedule.weekday = day" >
            <em class="tim-icons icon-simple-add pb-1"></em>
          </base-button>
        </div>
      </div>

      <modal
        v-if="schedule.weekday !== null"
        :show="isModalShow"
        :showClose="false"
        @hide="clearForm()"
        :title="`${$t('scheduleOn')} ${$tc(`dayOfWeek.${schedule.weekday}`, 2)}`"
        hide-footer
      >
        <header>
          <div class="row justify-content-between align-items-center mb-md-3">
            <h4 class="text-dark mb-0 col">
              {{ $t("scheduleOn") }} {{ $tc(`dayOfWeek.${schedule.weekday}`, 2) }}
            </h4>
            <base-button simple class="px-3 py-2 mr-3 col-1 d-flex justify-content-center" @click="closeModal()" data-testid="btn-closeModal">
              <em class="tim-icons icon-simple-remove text-dark"></em>
            </base-button>
          </div>
        </header>
        <hr />
        <form class="timetableForm" @submit.prevent="manageSchedule" id="timetableForm">
          <base-input :label="`${$t('scheduleStart')}*`" required class="inputTime">
            <el-time-select
              :class="inputErrorClass.start"
              v-model="schedule.start"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:59'
              }"
              placeholder="--:--"
              @change="disabledBtn(false)">
            </el-time-select>
          </base-input>
          <base-input :label="`${$t('scheduleEnd')}*`" required class="inputTime">
            <el-time-select
              :class="inputErrorClass.end"
              v-model="schedule.end"
              :picker-options="{
                start: schedule.start,
                step: '00:15',
                end: '23:59'
              }"
              placeholder="--:--"
              @change="disabledBtn(false)"
              :disabled="!schedule.start"
            >
            </el-time-select>
          </base-input>
        </form>
        <div class="row mt-3 justify-content-end">
          <div class="col-12 col-sm-6" v-if="schedule.id">
            <base-button block type="danger" variant="danger" @click="removeSchedule">{{ $t("delete") }}</base-button>
          </div>
          <div class="col-12 col-sm-6">
            <base-button block type="success" native-type="submit" form="timetableForm">{{ $t(schedule.id? "edit" : "add") }}</base-button>
          </div>
        </div>
      </modal>

      <modal :show="showConfirmLeave" :showClose="false" :centered="false">
        <template slot="header">
          <h5 class="modal-title">{{ $t("confirm.hasUnsavedChanges") }}</h5>
        </template>
        <template slot="footer">
          <base-button type="secondary" @click="showConfirmLeave = false">
            {{$t("cancel")}}
          </base-button>
          <base-button type="danger" @click="discardChanges()">
            {{$t("confirm.continue")}}
          </base-button>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { DateTime, Settings } from "luxon";
import { BaseButton, BaseInput, Modal } from "@/components/index";
import { mapState, mapActions } from "vuex";

export default {
  name: "timetable",
  props: {
    id: null,
    schedules: {
      default: null,
    },
  },
  data: () => {
    return {
      schedule: {
        start: null,
        end: null,
        weekday: null,
        manage: "show",
      },
      days: [1, 2, 3, 4, 5, 6, 0],
      storeSchedules: [],
      formatSchedules: [],
      isModalShow: false,
      inputErrorClass: {
        start: '',
        end: ''
      },
      isBtnDisabled: true,
      showConfirmLeave: false
    };
  },
  computed: {
    ...mapState([
      "hasChangeForm",
    ]),
  },
  methods: {
    manageSchedule() {
      const {end, start} = {...this.schedule};

      if(start && end) {
        this.schedule.start = DateTime.fromFormat(this.schedule.start ,'HH:mm').toFormat( "HH:mm" );
        this.schedule.end = DateTime.fromFormat(this.schedule.end ,'HH:mm').toFormat( "HH:mm" );

        this.schedule.id? this.updateSchedule() : this.addNewSchedule(); 
      }

      this.inputErrorClass.start = start? '' : 'border border-danger';
      this.inputErrorClass.end = end? '' : 'border border-danger';
    },
    addNewSchedule() {
      const schedule = { ...this.schedule };
      this.$emit('createSchedule', schedule)
      this.disabledBtn(true);
      this.endModificateSchedules();
    },
    updateSchedule() {
      this.$emit('editSchedule', this.schedule)
      this.disabledBtn(true);
      this.endModificateSchedules();
    },
    removeSchedule() {
      this.$emit('deleteSchedule', this.schedule.id)
      this.endModificateSchedules();
    },
    endModificateSchedules() {
      this.getFormatedSchedules();
      this.clearForm();
      this.isModalShow = false;
    },
    openScheduleToEdit(schedule) {
      this.schedule = { ...schedule };
      this.showModal("modal-datetime");
    },
    getFormatedSchedules() {      
      let objectSchedules = {};

      this.days.map((day) => {
        objectSchedules[day] = [];
        if (this.storeSchedules.length > 0) {

          this.storeSchedules.sort( (schedule1 , schedule2) =>{
            return Number(schedule1.start.substring(0,2)) - Number(schedule2.start.substring(0,2))
          })
          
          this.storeSchedules.map( schedule => {
            if (parseInt(schedule.weekday) === day) objectSchedules[day].push(schedule);
          });
        }
      });

      this.formatSchedules = objectSchedules;
    },
    discardChanges() {
      this.$store.dispatch('setHasChangeForm', false );
      this.showConfirmLeave = false;
      this.clearForm();
      this.closeModal;
    },
    disabledBtn(disabled) {
      this.$store.dispatch('setHasChangeForm', !disabled );
      this.isBtnDisabled = disabled;
    },
    clearForm() {
      this.schedule = {
        start: null,
        end: null,
        weekday: null,
      };
      this.inputErrorClass.start = '';
      this.inputErrorClass.end = '';
    },
    closeModal() {
      if(!this.hasChangeForm) {
        this.isModalShow = false
        this.clearForm();
      } else {
        this.showConfirmLeave = true
      }
    },
    checkSchedules() {
      if (Array.isArray(this.schedules) && this.schedules.length > 0) {
        this.storeSchedules = [];
        this.schedules.map((item) => this.storeSchedules.push(item));      
      } else {
        this.storeSchedules = [];
      }
    },
    showModal() {
      this.isModalShow = true;
    },
    ...mapActions(["setHasChangeForm"])
  },
  mounted() {
    this.checkSchedules();
    this.getFormatedSchedules();
  },
  watch: {
    schedules() {
      this.checkSchedules();
      this.getFormatedSchedules();
    },
  },
  components: {
    BaseButton,
    BaseInput,
    Modal
  },
};
</script>

<style>
.schedule {
  cursor: pointer;
  background-color: #f2f2f2;
}
.inputTime {
  width: 100%
}
.inputTime input {
  text-align: center;
}
.timetableForm {
  display: flex;
  flex-direction: column;
}
@media (min-width: 300px) {
  .inputTime {
    width: 35%;
  }
  
  .timetableForm  {
    flex-direction: row;
    justify-content: space-around;
  }
}
</style>
