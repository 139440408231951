<template>
  <div class="fileinput">
    <div
      class="fileinput-new thumbnail imageContainer"
      :class="{ 'img-circle': type === 'avatar' }"
    >
      <img :src="image" @error="replaceByDefault" class="imagePreview" :alt="$t(`alt.${altImage}`)"/>
    </div>
    <div class="mt-4">
      <base-button class="btn btn-primary btn-simple btn-file">
        <span class="fileinput-new">{{
          fileExists ? changeText : selectText
        }}</span>
        <input type="hidden" value="" name="" />
        <input
          :id="inputId"
          accept="image/*"
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"
          data-testid="inputFile"
        />
      </base-button>
      <base-button v-if="fileExists" @click="removeFile" round type="danger">
        <em class="tim-icons icon-simple-remove pb-1"></em> {{ removeText }}
      </base-button>
    </div>
  </div>
</template>
<script>
import { BaseButton } from "@/components/index";
import defaultImage from "../../assets/img/image_placeholder.jpg";

export default {
  name: "image-upload",
  props: {
    type: {
      type: String,
      default: "",
      description: 'Image upload type (""|avatar)',
    },
    src: {
      type: String,
      default: "",
      description: "Initial image to display",
    },
    selectText: {
      type: String,
      default: "Select image",
    },
    changeText: {
      type: String,
      default: "Change",
    },
    removeText: {
      type: String,
      default: "Remove",
    },
    altImage: {
      type: String,
      default: 'image'
    }
  },
  data() {
    return {
      imagePreview: null,
      inputId: new Date().getTime()
    };
  },
  computed: {
    placeholder() {
      return this.type === "avatar" ? require("../../assets/img/placeholder.jpg") : require("../../assets/img/image_placeholder.jpg")
    },
    fileExists() {
      return this.imagePreview !== null;
    },
    image() {
      return this.imagePreview || this.src || this.placeholder;
    },
  },
  methods: {
    handlePreview(event) {
      const file = event.target.files[0];
      if (file) {
        this.imagePreview = URL.createObjectURL(file);
        this.$emit("change", file);
      } 
    },
    removeFile() {
      this.imagePreview = null;
      document.getElementById(this.inputId).value = "";
      this.$emit("change", null);
    },
    cleanInput() {
      document.getElementById(this.inputId).value = "";
      this.imagePreview = null;
      this.$emit("change", null);
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    },
  },
  components: {
    BaseButton
  }
};
</script>
<style scoped>
.imageContainer {
  height: 200px !important;
  box-shadow: none !important;
  display: block!important;
  margin-left: auto;
  margin-right: auto;
}
.container {
  height: 200px !important;
}
@media (min-width: 576px) {
  .imageContainer {
    margin: 0 auto 0 0;
  }
  .imageContainer > img {
    display: block
  }
}
</style>
