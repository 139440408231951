<template>
  <div class="sidebar shadow" :data="backgroundColor">
    <div class="sidebar-wrapper text-left">
      <div class="logo d-flex">
        <div class="title text-center photo">
          <img
            class="logo-img"
            src="@/assets/img/Logo_AS.png"
            alt="Logo AS Saloon"
            width="100%"
            height="100%"
          />
        </div>
        <a
          href="javascript:void(0)"
          class="simple-text logo-normal 'text-left'"
        >
          {{ title }} <br><sub>version: {{ $version }}</sub>
        </a>
      </div>
      <ul class="nav">
        <slot>
          <sidebar-link> </sidebar-link>
        </slot>
      </ul>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";

export default {
  name: "side-bar",
  components: {
    SidebarLink,
  },
  props: {
    abv: {
      type: String,
      default: "CT",
    },
    title: {
      type: String,
      default: "Creative Tim",
    },
    backgroundColor: {
      type: String,
      default: "green",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeLinkIndex: 0,
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
  },
};
</script>
<style scoped>
.logo-img {
  width: 50px;
  height: 50px;
  padding: 4px;
  border-radius: 25px;
  background-color: white;
  margin-right: 10px;
  align-items: flex-end;
}
</style>
