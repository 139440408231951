import axios from "axios";
import i18n from "../i18n";
import Vue from "vue";
import router from "../router";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.params = {};
axios.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem("api_token")}`,
};
axios.interceptors.response.use(
  (response) => {
    if (
      localStorage.getItem("api_token") &&
      new Date(localStorage.getItem("token_expires_in")) < new Date()
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("userRole");
      localStorage.removeItem("api_token");
      localStorage.removeItem("token_expires_in");
      localStorage.removeItem("profilePicture");
      if (router.currentRoute.name !== "login") router.push({ name: "login" });
    }
    return response;
  },
  (error) => {
    const errorsNoToast = ["newRequest"];
    const isCancelRequest = error.__CANCEL__;
    if(error && !error.response){
      if(isCancelRequest || errorsNoToast.includes(error.message) ) return;

      Vue.$toast.error(i18n.t("notifications.error.somethingHasGoneWrong"), { timeout: 2000 });
      return
    }
    //Avoid show error in post visibility
    const response = error.response;
    if(response.config.url === "/visibility" && response.config.method === "post" && router.currentRoute.value.name === "Landing"){
      error.response.data.errors = [];
    }
    //Avoid show multilple errors when logout
    if(response.request && response.request.responseURL && response.request.responseURL.includes('logout')) return


    //ERROR FIELDS
    let fieldsErrors = error.response.data?.data;
    let messages = fieldsErrors?.message;

    if (messages && typeof messages !== "string") {
      for (const field in messages) {
        const allMessages = messages[field];
        allMessages.map((message) => {
          Vue.$toast.error(i18n.t(message), { timeout: 3000 });
        });
      }
      return;
    }
    //GENERAL ERROR
    let message;
    switch (error.response.status) {
      case 403:
        message = "notifications.error.forbidden";
        break;
      case 401:
        localStorage.removeItem("user");
        localStorage.removeItem("userRole");
        localStorage.removeItem("api_token");
        localStorage.removeItem("token_expires_in");
        localStorage.removeItem("profilePicture");
        if (router.currentRoute.name !== "login") router.push({ name: "login" });
        message = "notifications.error.unauthorized";
        break;
      case 422:
        message = "notifications.error.unprocessable";
        break;
      default:
        message = "notifications.error.general";
    }
    Vue.$toast.error(i18n.t(message), { timeout: 2000 });
    return;
  }
);

export default axios;
