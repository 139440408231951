import BaseButton from "@/components/white-dashboard/BaseButton.vue";
import BaseCheckbox from "@/components/white-dashboard/BaseCheckbox.vue";
import BaseRadio from "@/components/white-dashboard/BaseRadio.vue";
import DropDown from "@/components/white-dashboard/Dropdown.vue";
import Notify from "@/components/white-dashboard/NotificationPlugin";

import "@/assets/scss/white-dashboard.scss";
import "@/assets/css/nucleo-icons.css";
import "@/assets/demo/demo.css";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("base-button", BaseButton);
    Vue.component("base-checkbox", BaseCheckbox);
    Vue.component("base-radio", BaseRadio);
    Vue.component("drop-down", DropDown);
    Vue.component("notify", Notify);
  },
};

export default GlobalComponents;
