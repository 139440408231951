<template>
    <div class="bg-white p-2">
        <div class="m-4">
            <div class="card shadow px-3 py-4 card-cto">
                <h2 class="text-dark phrase-cta text-center pt-2">{{ $t('how') }}</h2>
                <p class="text-dark text-center phrase pb-4">{{ $t('allToolsInOnePlace') }}</p>

                <img src="@/assets/img/landing-img.png" alt="landing image" class="landing-img" loading="lazy">
                <collapsible-groups :items="groups" @open-group="openGroup($event)" />
            </div>
        </div>
    </div>
</template>

<script>
import { CollapsibleGroups } from "@/components/index";

export default {
    name: "ToolsApp",
    data() {
        return {
            groups: [],
        };
    },
    methods: {
        openGroup({ id }) {
            const isPair = (id % 2) === 0;

            if (this.isViewDesktop) {
                const partnerId = isPair ? id + 1 : id - 1;

                this.groups[id].isActive = !this.groups[id].isActive;
                if (partnerId < this.groups.length) {
                    this.groups[partnerId].isActive = !this.groups[partnerId].isActive;
                }
            } else {
                this.groups[id].isActive = !this.groups[id].isActive;
            }
        },
        setGroups() {
            let tools = ["appointmentManagement", "reminders", "statistics", "controlEmployees", "managerFunctions", "loyalty", "CMS", "protectionPolicies"]
            this.groups = tools.map(item => {
                let group = {
                    isActive: false,
                    title: this.$t(`marketingSection.descriptionTools.${item}.title`),
                    description: this.$t(`marketingSection.descriptionTools.${item}.description`),
                }
                return group
            })
        },
    },
    mounted() {
        this.setGroups()
    },
    components: {
        CollapsibleGroups
    },
};
</script>
<style scoped lang="scss">
@import '@/assets/scss/white-dashboard.scss';

i {
    font-size: 50px;
}

.image {
    border: 10px solid black;
    height: 30em;
    width: 19em;
    border-radius: 1rem;
}

.containerForm {
    border-radius: 1em;
}

.phrase {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 3.5rem;
}

.phrase-cta {
    font-size: 2.2em;
    font-weight: bold;
}

.cta {
    font-size: 1.64em;
    font-weight: bold;
}

.startFreeTrialBtn {
    height: 5em;
    font-size: 1.5em;
}

.startFreeTrialBtn>span,
.startTestBtn>span {
    white-space: break-spaces;
}

.svgIcon {
    width: 5em;
}

.card-cto {
    border: 2px solid $primary;
}

.landing-img {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 1rem;
}

.landingContainer {
    max-width: 1500px;
    margin: 0 auto;

    .header-icons-texts {
        font-weight: normal;
        font-size: 15px;
    }

    .bg-white {
        border-radius: 1rem;
    }

    .text-cta {
        h2 {
            color: #088375;
            margin: 10px 0;
            font-size: 2rem;
        }

        h3 {
            margin-bottom: 10px !important;
        }
    }

    .description-text {
        font-weight: 400 !important;

        span {
            font-weight: bolder !important;
        }
    }
}

@media (min-width: 768px) {
    .containerForm {
        max-width: 450px;
    }

    .title-text {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: left;
    }

    .description-text {
        font-size: 1rem;
    }
}
</style>
