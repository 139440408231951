<template>
  <div class="d-md-flex flex-md-wrap card-collapse accordion">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'collapse',
  data() {
    return {
      items: [],
    };
  },
};
</script>

<style scoped></style>
