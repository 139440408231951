<template>
  <card type="plain" body-classes="p-0 shadow-sm border">
    <div id="regularMap" class="map"></div>
  </card>
</template>
<script>
import { Card } from "@/components/index";
import { API_KEY } from "./API_KEY";
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    longitude: {
      type: Number,
      required: true
    },
    latitude: {
      type: Number,
      required: true
    },
    address: {
      type: String,
      required: true
    },
  },
  components: {
    Card
  },
  methods: {
    initApiGoogle(){
      const loader = new Loader({
        apiKey: API_KEY,
        version: "weekly",
        libraries: ["places"]
      });
      loader
          .load()
          .then((google) => {
            this.google = google;
            this.initMap()
          })
          .catch(e => {
            console.error(e);
          });
    },
    initMap() {
      const self = this;
      const myLatlng = new this.google.maps.LatLng(this.latitude, this.longitude);
      const mapOptions = {
        zoom: 16,
        center: myLatlng,
      };

      const map = new this.google.maps.Map(
          document.getElementById("regularMap"),
          mapOptions
      );

      const marker = new this.google.maps.Marker({
        position: myLatlng,
        draggable: true,
      });

      this.google.maps.event.addListener(marker, "dragend", function (e) {
        const latLng = {
          lat: parseFloat(e.latLng.lat().toFixed(6)),
          lng: parseFloat(e.latLng.lng().toFixed(6)),
        };
        self.$emit("place-selected", latLng);
      });

      marker.setMap(map);
    },
  },
  watch: {
    address: function () {
      this.initMap();
    }
  },
  mounted() {
    this.initApiGoogle();
  }
};
</script>
<style></style>