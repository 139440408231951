<template>
  <el-popover trigger="click" placement="top">
  <div class="popoverContainer">
    <h3 class="popover-header" v-if="Title">{{Title}}</h3>
    <div class="popover-body" v-if="body">{{body}}</div>
  </div>
  <base-button slot="reference" class="btnPopover" :class="reason ? `btn-${reason}`: 'btn-default'">
    {{textBtn}}
  </base-button>
</el-popover>
</template>

<script>
import {Popover} from 'element-ui'
import { BaseButton } from '@/components/index';

export default {
  name: "popover",
  props: {
    textBtn: String,
    Title: String,
    body: String,
    reason: String
  },
  components: {
    'el-popover': Popover,
    BaseButton
  },
};
</script>

<style>
.el-popover {
  max-width: 400px!important;
}
</style>

<style scoped lang="scss">
@import '../../assets/scss/white-dashboard.scss';

.btnPopover {
  background: $secondary;
  color:white;
  position: absolute;
  width: auto;
  display: initial;
  padding: 0px 5px;
  margin: 0 0 0 5px;
}
.btnPopover:hover, .btnPopover:focus, .btnPopover:active {
  background: $tertiary!important;
  box-shadow: none !important;
}
.btn-help {
  font-size: 10px;
}
.popover-body {
  word-break: initial;
}
</style>
