import Vue from "vue";
import VueRouter from "vue-router";
import axios from 'axios';
import store from "../store/index";

const customerString = 'customer';
const employeeString = 'employee';
const adminString = 'admin';
const managerString = 'manager';

Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../shared/Dashboard/DashboardLayout.vue"),
    children: [
      {
        path: "/bookings",
        name: "bookings",
        component: () => import("../views/Bookings/Bookings.vue"),
      },
      {
        path: "/users",
        name: "userList",
        component: () => import("../views/UserManagement/UserList.vue"),
      },
      {
        path: "/stores",
        name: "storeAdminList",
        component: () => import("../views/StoreManagement/StoreList.vue"),
      },
      {
        path: "/mail",
        name: "mail",
        component: () => import("../views/MailManagement/MailManagement.vue"),
      },
      {
        path: "/users/new",
        name: "newUser",
        component: () => import("../views/UserManagement/UserForm.vue"),
      },
      {
        path: "/users/:id",
        name: "manageUser",
        component: () => import("../views/UserManagement/UserForm.vue"),
      },
      {
        path: "/customers",
        name: "customerList",
        component: () => import("../views/Customers/CustomersList.vue"),
      },
      {
        path: "/customers/new",
        name: "newCustomer",
        component: () => import("../views/Customers/CustomerNew.vue"),
      },
      {
        path: "/customers/:id",
        name: "customersManagement",
        component: () => import("../views/Customers/CustomersManagement.vue"),
      },
      {
        path: "/storeManagement/:id?",
        name: "storeManagement",
        component: () => import("../views/StoreManagement/StoreManagement.vue"),
      },
      {
        path: "/employees",
        name: "employeeList",
        component: () => import("../views/Employees/EmployeeList.vue"),
      },
      {
        path: "/employees/new",
        name: "newEmployee",
        component: () => import("../views/Employees/EmployeeNew.vue"),
      },
      {
        path: "/employees/:id",
        name: "employeeManagement",
        component: () => import("../views/Employees/EmployeesManagement.vue"),
      },
      {
        path: "/managers",
        name: "managerList",
        component: () => import("../views/ManagersManagement/ManagerList.vue"),
      },
      {
        path: "/managers/new",
        name: "newManager",
        component: () => import("../views/ManagersManagement/ManagerForm.vue"),
      },
      {
        path: "/managers/:id",
        name: "manageManager",
        component: () => import("../views/ManagersManagement/ManagerForm.vue"),
      },
      {
        path: "/services",
        component: () =>
          import("../views/ServicesManagement/ServicesManagement.vue"),
        children:[
          {
            path: "",
            name: "servicesList",
            component: () =>
            import("../views/ServicesManagement/Services/ServicesList.vue"),
          },
          {
            path: "form/:id",
            name: "servicesManage",
            component: () =>
            import("../views/ServicesManagement/Services/ServiceForm.vue"),
          },
          {
            path: "form",
            name: "servicesNew",
            component: () =>
            import("../views/ServicesManagement/Services/ServiceForm.vue"),
          },
          {
            path: "categories/form",
            name: "servicesCategoryNew",
            component: () =>
              import("../views/ServicesManagement/ServicesCategories/ServiceCategorieForm.vue"),
          },
          {
            path: "categories/form/:id",
            name: "servicesCategoryManage",
            component: () =>
              import("../views/ServicesManagement/ServicesCategories/ServiceCategorieForm.vue"),
          },
        ]
      },
      {
        path: "/statistics",
        name: "statistics",
        component: () => import("../views/Statistics/StatisticsManagement.vue"),
      },
      {
        path: "/categories",
        name: "categoryList",
        component: () => import("../views/CategoryManagement/CategoryList.vue"),
      },
      {
        path: "/categories/new",
        name: "newCategory",
        component: () => import("../views/CategoryManagement/CategoryForm.vue"),
      },
      {
        path: "/categories/:id",
        name: "manageCategory",
        component: () => import("../views/CategoryManagement/CategoryForm.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("../shared/PublicPagesNavbar/NavbarLayout.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../shared/Auth/Login.vue"),
        beforeRouteEnter: (to, from, next) => {
          if (localStorage.getItem("api_token")) {
            next({ name: "bookings" });
          }
        },
      },
      {
        path: "/register",
        name: "register",
        component: () => import("../shared/Auth/Register.vue"),
      },
      {
        path: "/profile",
        name: "editProfile",
        component: () => import("../views/Profile/EditProfile.vue"),
      },
      {
        path: "/myAppointments",
        name: "myAppointments",
        component: () => import("../views/MyAppointments/MyAppointments"),
      },
      {
        path: "/",
        name: "storeList",
        component: () => import("../views/Stores/StoreList.vue"),
        children: [
          {
            path: "/stores/:slug",
            meta: { label: "storeListFiltered" },
            name: "storeListFiltered",
            component: () => import("../views/Stores/StoreList.vue"),
          }
        ]
      },
      {
        path: "/promociona-tu-negocio",
        name: "joinUs",
        component: () => import("../shared/LandingPages/BusinessInformation.vue"),
      },
      {
        path: "/service-contract",
        name: "serviceContract",
        component: () => import("../shared/LandingPages/ServiceContractForm.vue"),
      },
      {
        path: "/delete-account",
        name: "deleteAccount",
        component: () => import("../shared/LandingPages/DeleteAccount.vue"),
      },
      {
        path: "/feria-de-belleza-moda-2024",
        name: "BeautyFashionForm",
        component: () => import("../shared/LandingPages/BeautyFashionForm.vue"),
      },
      {
        path: "/politica-de-privacidad",
        name: "privacyPolicy",
        component: () => import("../shared/PublicPagesNavbar/privacyPolicy.vue"),
      },
      {
        path: "/cookies",
        name: "cookies",
        component: () => import("../shared/PublicPagesNavbar/cookies.vue"),
      },
      {
        path: "/who-we-are",
        name: "whoWeAre",
        component: () => import("../shared/PublicPagesNavbar/WhoWeAre.vue"),
      },
      {
        path: "/use-guide",
        name: "useGuide",
        component: () => import("../shared/PublicPagesNavbar/useGuide.vue"),
      },
      {
        path: "/integrations",
        name: "integrations",
        component: () => import("../shared/PublicPagesNavbar/Integrations.vue"),
      },
      {
        path: "/aviso-legal",
        name: "legalNotice",
        component: () => import("../shared/PublicPagesNavbar/legalNotice.vue"),
      },
      {
        path: "/terminos-y-condiciones",
        name: "termsAndConditions",
        component: () => import("../shared/PublicPagesNavbar/termsAndConditions.vue"),
      },
      {
        path: "/not-found",
        name: "notFound",
        component: () => import("../views/NotFound/NotFound.vue"),
      },
      {
        path: "/:slug",
        meta: { label: "Store" },
        name: "storeView",
        component: () => import("../views/Stores/StoreView.vue"),
      },
      {
        path: "/:slug/:serviceSlug",
        meta: { label: "Store" },
        name: "storeService",
        component: () => import("../views/Stores/StoreService.vue"),
      },
      {
        path: "/:slug/:serviceSlug/reservar",
        meta: { label: "Store" },
        name: "serviceBooking",
        component: () => import("../views/Stores/ServiceBooking.vue"),
      },
      {
        path: "*",
        redirect: () => ({ name: 'norFound' }),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active",
  linkActiveClass: "active",
});

const routesFor = {
  auth: ["login", "register"],
  public: ["storeList" ,"storeView", "storeService" , "serviceBooking", "joinUs", "legalNotice", "termsAndConditions", "cookies", "privacyPolicy", "notFound", "storeListFiltered", "whoWeAre", "deleteAccount", "serviceContract", "BeautyFashionForm", "useGuide", "integrations"],
  customer: ["myAppointments", "editProfile", "notFound"],
  employee: ["bookings", "customerList", "newCustomer", "customersManagement", "editProfile", "notFound"],
  admin: ["userList", "storeAdminList", "storeManagement", "newUser", "manageUser", "newCategory", "categoryList", "manageCategory", "editProfile", "notFound",  "managerList", "newManager", "manageManager", "statistics","mail"],
  manager: ["bookings", "customerList", "storeManagement", "newCustomer", "customersManagement", "employeeList", "newEmployee", "employeeManagement", "servicesManagement", "servicesCategoryNew", "servicesCategoryManage", "servicesList", "servicesManage", "servicesNew", "editProfile", "statistics", "notFound"]
}

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch(() => console.warn("the redirection was stopped"));
}

router.beforeEach(async(to, from, next) => {
  const apiToken = localStorage.getItem('api_token');
  const isAuth = Boolean(apiToken);

  if(to.name === 'notFound') next();
  
  if (from.name === "storeList" || from.name === "storeView"){
    store.dispatch("setPreviousPage", from.path);
  }

  if(store.state.hasChangeForm){
    store.dispatch('setNextPage', to.path);
    store.dispatch('setIsOpenModal', true);
    next(false);
  } else {
    const userRole = localStorage.getItem("userRole")?.toLowerCase();
    
    if(isAuth && routesFor.auth.includes(to.name)) {
      const isCustomer = userRole === customerString;

      next(isCustomer? '/' : routesFor[userRole][0] );
      return;
    }
    
    if (!isAuth && (!routesFor.public.includes(to.name) && !routesFor.auth.includes(to.name))  ) {
        next({ name: "login" });
    } else if (isAuth && !routesFor.public.includes(to.name)) {
      if (routesFor.manager.includes(to.name) && userRole === managerString) {
        next();
      } else if (routesFor.admin.includes(to.name) && userRole === adminString) {
        next();
      } else if (routesFor.employee.includes(to.name) && userRole === employeeString) {
        next();
      } else if (routesFor.customer.includes(to.name) && userRole === customerString) {
        next();
      } else {
        next({ name: "notFound" });
      }
    } else {
      next();
    }
  }
});

export default router;
