
<template>
  <div class="fileinput text-left">
    <div>
      <span class="btn btn-primary btn-simple btn-file shadow-sm">
        <span class="fileinput-new">{{ selectText }}</span>
        <input
          id="input"
          accept="image/*"
          @change="handlePreview"
          type="file"
          name="..."
          class="valid text-left"
          multiple
          aria-invalid="false"
        />
      </span>
    </div>
    <div class="imagesPreview p-4 my-4 shadow-sm" v-if="isShowImagesPreview">
      <div
        v-for="(img, index) in imagesPreview"
        :key="index"
        class="imagePreviewContainer"
      >
        <div class="fileinput-new thumbnail">
          <img :src="img.url" alt="Preview image" class="preview-image" width="48rem" height="48rem"/>
        </div>
        <h4 class="title-image">{{ img.name }}</h4>
        <p class="size-image">{{ Math.round(img.size / 1024) }} KB</p>
        <div class="btn-image text-center">
          <span class="tim-icons icon-simple-remove pb-1" @click="removeFile(index)"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "image-upload-multiple",
  props: {
    src: {
      type: Array,
      default: () => [],
      description: "Initial images to display",
    },
    isSaved: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    let imgPlaceholder = require("@/assets/img/image_placeholder.jpg");
    return {
      placeholder: imgPlaceholder,
      imagesPreview: null,
      files: [],
      isShowImagesPreview: false,
      filesTemp: null,
      selectText: this.$t('select.pictures')
    };
  },
  methods: {
    handlePreview(event) {
      this.files = Array.from(event.target.files);
      this.selectText = this.$t('select.morePictures')
             
      if(this.filesTemp && !this.isSaved) this.filesTemp.forEach( file => this.files.push(file));

      this.filesTemp = [...this.files];
      this.imagesPreview = [];

      if(this.files.length > 0) this.isShowImagesPreview = true;

      this.files.forEach((file) =>
        this.imagesPreview.push({
          name: file.name,
          size: file.size,
          url: URL.createObjectURL(file),
        })
      );

      this.$emit("change", this.files);
    },
    removeFile(index) {
      this.filesTemp.splice(index, 1);
      this.imagesPreview.splice(index, 1);
      this.files.splice(index, 1);

      if(this.imagesPreview.length === 0) {
        this.selectText = this.$t('select.pictures')
        this.isShowImagesPreview = false;
        document.getElementById('input').value = "";
      }

      let cloneFiles = Object.assign({}, this.files);
      delete cloneFiles[index];

      this.$emit("change", cloneFiles);
    },
    cleanInput() {
      this.selectText = this.$t('select.pictures');
      this.filesTemp = [];
      this.imagesPreview = null;
      this.isShowImagesPreview = false;
    },
  },
};
</script>
<style>
.fileinput {
  width: 100%;
}
.imagePreviewContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.imagesPreview {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%236C757DFF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='11' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 10px;
}
.preview-image {
  width: 50px;
  border-radius: 5px;
}
.title-image {
  flex-grow: 1;
  margin: 0;
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.size-image {
  width: 10%;
  margin: 0;
}
.btn-image {
  width: 5%;
  cursor: pointer;
  color: red;
  font-size: 15px;
}
.btn-image > i:hover {
  text-shadow: 1px 1px 2px lightslategray;
  font-weight: bolder;
}
.btn-image > i:active {
  transform: scale(.9);
}
.btn-file {
  width: 100%;
}
@media (min-width: 576px) {
  .btn-file {
    width: auto;
  }
}
</style>
