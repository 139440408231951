<template>
  <carousel
    :perPageCustom="[
      [0, 20],
      [479, 3],
      [768, 6],
      [1024, 8],
    ]"
    :pagination-enabled="true"
    class="tabsCarousel"
  >
    <slide v-if="withAllBtn" class="mx-auto px-2 p-2 min-w">
      <base-button
        round
        class="w-100 btn-small-font"
        type="tertiary"
        :class="selectedTab === initialTextTab ? 'background-bottom-selected' : ''"
        @click="changeTab({id: null, name: initialTextTab})"
        >{{ $t(initialTextTab).toUpperCase() }}
      </base-button>
    </slide>
    <slide v-for="(tab, index) in tabs" :key="index" class="mx-auto px-2 py-2 min-w">
      <base-button
        round
        class="w-100 btn-small-font"
        type="tertiary"
        :class="selectedTab === tab.name ? 'background-bottom-selected' : ''"
        @click="changeTab(tab)"
        >{{ tab.name.toUpperCase() }}
      </base-button>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "TabsCarousel",
  props: {
    "tabs": {
      type: Array,
      required: true
    },
    "initialTextTab": {
      type: String,
      default: 'home'
    },
    "withAllBtn": {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      selectedTab: this.initialTextTab
    };
  },
  methods: {
    setSelectedTab(tab){
      this.selectedTab = tab.name;
    },
    changeTab(tab){
      this.selectedTab = tab.name;
      this.$emit('tabChanged', tab);
    }
  },
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style scoped>
.background-bottom-selected {
  background-color: #5d6988;
}
.btn-small-font:focus {
  background: #5d6988!important;
}
.min-w {
    min-width: 100%;
}
@media (min-width: 400px) {
  .min-w {
    min-width: 200px!important;
  }
}
@media (max-width: 768px) {
  .btn-small-font {
    font-size: 0.675rem;
  }
}
.btn-small-font {
  display: flex;
  justify-content: center;
}
</style>
