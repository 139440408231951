<template>
  <div class="card text-white header-height margin-img">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "HeaderImage",
};
</script>

<style scoped>
.header-height {
  width: 100%;
  height: 690px;
}
.header-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);
  object-position: center 5%;
}
.margin-img {
  margin-top: -100px;
}
</style>
