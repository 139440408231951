<template>
  <span
    class="badge"
    :class="[
      { [`badge-${type}`]: type },
      { [`badge-${statusClass}`]: status },
      { 'badge-pill': pill },
    ]"
  >
    {{ text }}
  </span>
</template>
<script>
export default {
  name: "badge",
  props: {
    status: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    pill: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("input", check);
      },
      inlineClass() {
        if (this.inline) {
          return `form-check-inline`;
        }
      },
    },
    statusClass() {
      let classBadge;
      switch (this.status) {
        case "new":
          classBadge = "info";
          break;
        case "managed":
          classBadge = "primary";
          break;
        case "delivered":
          classBadge = "success";
          break;
        default:
          classBadge = "secondary";
          break;
      }
      return classBadge;
    },
  },
};
</script>
