<template>
  <div
    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
  >
    <div>
      <p class="card-category">
        {{
          `${$t("paginationText.show")} ${startItem} ${$t(
            "paginationText.to"
          )} ${lastItem} ${$t("paginationText.of")} ${
            totalItems != null ? totalItems : 1
          } ${$tc(element, 2)}`
        }}
      </p>
    </div>
    <ul class="pagination pagination-no-border pagination-primary">
      <li class="page-item prev-page" :class="{ disabled: currentPage === 1 || isChanging }">
        <a
          @click="turnPage(currentPage - 1)"
          aria-label="Previous"
          class="page-link"
          ><i aria-hidden="true" class="tim-icons icon-double-left"></i
        ></a>
      </li>
      <li
        v-for="page in pages"
        :key="page.index"
        class="page-item"
        :class="{
          active: currentPage === page,
          disabled: currentPage === page || isChanging
        }"
      >
        <a class="page-link" @click="turnPage(page)">{{ page }}</a>
      </li>
      <li
        class="page-item page-pre next-page"
        :class="{ disabled: currentPage === lastPage || isChanging }"
      >
        <a
          @click="turnPage(currentPage + 1)"
          aria-label="Next"
          class="page-link"
          ><i aria-hidden="true" class="tim-icons icon-double-right"></i
        ></a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "pagination",
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      default: 15,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    element: {
      type: String,
      default: "item",
    },
    isChanging: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startItem() {
      return this.currentPage > 1
        ? this.currentPage * this.perPage - this.perPage
        : 1;
    },
    lastItem() {
      let lastItem = this.perPage * this.currentPage;
      if (lastItem > this.totalItems) {
        lastItem = this.totalItems;
      }
      return lastItem;
    },
    pages() {
      let pages = [];
      let startPage = this.currentPage > 2 ? this.currentPage - 2 : 1;
      for (let i = startPage; i < startPage + 4; i++) {
        if (i > this.lastPage) {
          break;
        }
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    turnPage(page) {
      this.$emit("turnPage", page);
    },
    setPages() {
      let pages = [];
      let startPage = this.currentPage > 2 ? this.currentPage - 2 : 1;
      for (let i = startPage; i < startPage + 5; i++) {
        if (i > this.lastPage) {
          break;
        }
        pages.push(i);
      }
      this.pages = pages;
    },
  },
};
</script>
<style>
.card-category {
  text-transform: none !important;
}
</style>
