<template>
  <collapse>
    <collapse-item v-for="(item, i) in items" :name="i" :id="i" :key="i" :active="item.isActive" @open-group="$emit('open-group', $event)" class="d-flex flex-wrap">
      <template v-slot:title>
        <h2 class="mb-0 collapseTitle">{{ item.title }}</h2>
      </template>
      <p class="collapseDescription">
        {{ item.description }}
      </p>
    </collapse-item>
  </collapse>
</template>

<script>
import { Collapse } from '@/components/index.js';
import CollapseItem from '@/components/white-dashboard/CollapseGroups/CollapseItem.vue';

export default {
    props: {
        items: {
            type: Array,
            required: true,
            default: function () {
                return []
            }
        },
        animationDuration: {
            type: Number,
            default: 200,
        },
        multipleActive: {
          type: Boolean,
          default: false,
        }
    },
    components: {
      'collapse': Collapse,
      'collapse-item': CollapseItem
    }
}
</script>

<style >
.collapseTitle {
  font-size: 1.2em;
  font-weight: bold;
}
.collapseDescription {
  font-size: 1rem;
}
ul.el-carousel__indicators {
  display: flex;
  justify-content: center;
}
</style>