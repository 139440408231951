var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",class:[
    { 'show d-block': _vm.show },
    { 'd-none': !_vm.show },
    { 'modal-mini': _vm.type === 'mini' },
  ],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"modal-dialog",class:[
      { 'modal-notice': _vm.type === 'notice' },
      { 'modal-dialog-centered': _vm.centered },
      _vm.modalClasses,
    ]},[_c('div',{staticClass:"modal-content",class:[
        _vm.gradient ? `bg-gradient-${_vm.gradient}` : '',
        _vm.modalContentClasses,
      ]},[(_vm.$slots.header)?_c('div',{staticClass:"modal-header",class:[_vm.headerClasses]},[_vm._t("header"),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"close",attrs:{"type":"primary","data-dismiss":"modal","aria-label":"Close"},on:{"click":_vm.closeModal}},[_c('em',{staticClass:"tim-icons icon-simple-remove"})]):_vm._e()]})],2):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2):_vm._e(),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }