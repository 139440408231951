<template>
  <div class="echarts">
    <v-chart ref="chart" :options="chartOptionsMixed" autoresize />
  </div>
</template>
  
<script>
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';

const colors = [ '#3DADA1', '#210070' ];

export default {
  name: 'MixedChart',
  data() {
    return {
      chartOptionsMixed: {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'cross' },
          backgroundColor: '#fff',
          borderColor: '#ccc',
          borderWidth: 1,
          textStyle: {
            color: '#000',
            background: '#fff',
            fontSize: '14',
            fontWeigth: 'bold'
          },
          formatter: (args) => `
            <span >${args[0].name}</span>
            <br />
            <p style="padding:5px 5px 0px;">${args[0].marker}${args[0].seriesName}: ${args[0].data}</p>
            <p style="padding:5px 5px 0px;">${args[1].marker}${args[1].seriesName}: ${args[1].data}</p>
          `,
        },
        legend: {
          data: [
            this.chartData.datasets[0].label,
            this.chartData.datasets[1].label
          ]
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: this.chartData.labels
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: this.chartData.datasets[0].label,
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            }
          },
          {
            type: 'value',
            name: this.chartData.datasets[1].label,
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1]
              }
            }
          }
        ],
        series: [
          {
            name: this.chartData.datasets[0].label,
            type: 'bar',
            data: this.chartData.datasets[0].data
          },
          {
            name: this.chartData.datasets[1].label,
            type: 'line',
            yAxisIndex: 1,
            data: this.chartData.datasets[1].data
          }
        ]
      }
    }
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        datasets: []
      })
    }
  },
}
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 35rem;
  padding: 2rem 0;
}
.tooltipTitle {
  color:red;
}
</style>