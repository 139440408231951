<template>
  <div class="search-bar-sticky p-0">
    <card
      bodyClasses="p-0 shadow d-flex align-items-center shadow-sm"
      class="m-0 m-auto"
      :class="scrollY < 352 ? 'search-width' : 'search-width-100 rounded-0'"
    >
      <div class="col-md-1 ml-5" :class="scrollY < 352 ? 'd-none' : 'd-none d-md-block'">
        <Logo></Logo>
      </div>

      <div
        class="row col-12 col-md-8 col-lg-9 align-items-center mx-2 m-auto bg-white search-height search-input-width"
        :class="scrollY < 352 ? 'rounded search-height' : 'search-height-sm'"
      >
        <div class="col-12 col-md-6 pr-0 search-input" :class="{'col-md-12': $route.name === 'notFound'}">
          <vue-typeahead-bootstrap
              input-class="border-left-0 border-right-0 border-top-0 rounded-0"
              :placeholder="$tc('searchBar.search')"
              v-model="serviceInput"
              :data="data"
              :show-all-results="true"
              :disabled-values="[$tc('store', 2), $tc('services', 2)]"
              :serializer="item => item.name"
              :min-matching-chars="3"
              @input="search"
              @hit="clickStoreService"
              disable-sort
              :background-variant-resolver="(data) => data.isTitle ? 'secondary':''"
          >
            <template slot="prepend">
              <em class="tim-icons icon-zoom-split mr-3">
              </em>
            </template>

            <template slot="suggestion" slot-scope="{ data, htmlText }">
              <div class="row align-items-center">
                <span class="col-8 col-sm-9 col-lg-10" v-html="htmlText"></span>
                <span class="col-4 col-sm-3 col-lg-2"></span>
                <p class="col-8 col-sm-9 col-lg-10 text-black-50 font-size m-0">{{ data.address }}</p>
              </div>
            </template>
          </vue-typeahead-bootstrap>
        </div>
        
        <div v-if="$route.name !== 'notFound'" class="col-12 col-md-6 p-0 search-input">
          <input-places-autocomplete
            class="whereInput"
            v-model="filters.location"
            v-on:place-selected="placeSelected"
            @valueChange="placeSelected"
            placeholder="searchBar.where"
          />
        </div>
      </div>

      <div
        class="col-md-2 col-xxl-1"
        :class="scrollY < 352 ? 'd-none' : 'd-none d-md-block'"
      >
        <ul class="navbar-nav">
          <li class="d-flex justify-content-center px-0">
            <div v-if="!isAuthenticated" class="nav-link">
              <base-button
                v-if="$route.name !== 'login'"
                simple
                class="position-relative btn-custon px-0 d-none d-sm-block"
                @click="goToLogin"
                >{{ $tc("login") }}</base-button
              >
              <base-button
                v-if="$route.name === 'login'"
                simple
                class="position-relative btn-custon ml-0"
                @click="goToRegister"
                >{{ $tc("register") }}</base-button
              >
            </div>
            <div v-else>
              <drop-down>
                <a href="javascript:void(0);" class="d-flex dropdown-toggle" data-toggle="dropdown">
                  <div class="photo">
                    <img v-if="profilePicture" :src="profilePicture" @error="replaceByDefault" class="profile-img" alt="Profile Photo" width="100%" height="100%"/>
                    <img v-else src="@/assets/img/anime3.png" alt="Profile Photo" class="profile-img" width="100%" height="100%"/>
                  </div>
                  <strong class="caret d-none d-lg-block d-xl-block"></strong>
                </a>
                <ul class="dropdown-menu dropdown-navbar">
                  <li class="nav-link " @click="goToEditProfile">
                    <a href="javascript:void(0)" class="nav-item dropdown-item">
                      <em class="tim-icons icon-single-02"></em>
                      {{ $t("myProfile") }}
                    </a>
                  </li>

                  <li v-if="isCustomer" class="nav-link" @click="goToMyAppointmets">
                    <a href="javascript:void(0)" class="nav-item dropdown-item">
                      <em class="tim-icons icon-calendar-60"></em>
                      {{ $t("myAppointments") }}
                    </a>
                  </li>

                  <li class="nav-link" @click="logout()">
                    <a href="javascript:void(0)" class="nav-item dropdown-item" >
                      <em class="tim-icons icon-button-power"></em>
                      {{ $t("logout") }}
                    </a >
                  </li>
                </ul>
              </drop-down>
            </div>
          </li>
        </ul>
      </div>
    </card>
  </div>
</template>

<script>
import { Card, BaseButton, InputPlacesAutocomplete } from "@/components/index";
import { mapState } from "vuex";
import DropDown from "@/components/white-dashboard/Dropdown.vue";
import Logo from "@/components/Logo.vue";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import defaultImage from "../../assets/img/image_placeholder.jpg";

export default {
  name: "SearchBar",
  props: {
    selectedStoreCategory: {
      type: Object,
      default: () => ({ id: null, code: 'all' })
    }
  },
  data() {
    return {
      route: "/auth/logout",
      home: "/",
      dashboard: "/dashboard",
      profilePicture: null,
      scrollY: null,
      data: [],
      isSearchStoreService: false,
      filters: {
        service_slug: null,
        location: null,
        store_category_id: null
      },
      timerTypingSearch: null,
      serviceInput: null
    };
  },
  computed: {
    ...mapState([
      "isAuthenticated",
      "isAdmin",
      "isManager",
      "isEmployee",
      "isCustomer",
      "changedProfilePicture",
    ]),
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    goToRegister() {
      this.$router.push({ name: "register" });
    },
    async logout() {
      let response = await this.axios.get(this.route);
      if (response && response.data.status === "success") {
        localStorage.removeItem("user");
        localStorage.removeItem("userRole");
        localStorage.removeItem("api_token");
        localStorage.removeItem("token_expires_in");
        localStorage.removeItem("profilePicture");
        await this.$router.push({name: "login"});
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async goToEditProfile() {
      await this.$store.dispatch("setPreviousPage", location.pathname);
      await this.$router.push("/profile");
    },
    goToMyAppointmets() {
      if(this.$router.history.current.name !== "myAppointments") {
        this.$store.dispatch("setPreviousPage", location.pathname);
        this.$router.push({ name: "myAppointments" });
      }
    },
    async search(inputValue) {
      if(this.timerTypingSearch) {
        clearTimeout(this.timerTypingSearch);
        this.timerTypingSearch = null;
      }

      this.timerTypingSearch = setTimeout( async () => {
        inputValue = String(inputValue).trimStart().trimEnd();
  
        if (inputValue.length >= 3) {
          const routeStore = `/search/stores?query=${inputValue}`;
          let responseStores = await this.axios.get(routeStore, { params: inputValue });

          const routeServices = `/search/services?query=${inputValue}`;
          let responseServices = await this.axios.get(routeServices, { params: inputValue });
          this.data = [];

          if (responseStores && responseStores.data.status === "success") {
            if (responseStores.data.data.stores.length > 0) {
              this.data.push({ name: this.$tc("store", 2), isTitle: true });
            }
            responseStores.data.data.stores.forEach(store => {
              store.item = "store";
              this.data.push(store);
            });
          }

          if (responseServices && responseServices.data.status === "success") {
            if (responseServices.data.data.services.length > 0) {
              this.data.push({ name: this.$tc("services", 2), isTitle: true });
            }
            responseServices.data.data.services.forEach(service => {
              service.item = "service"
              this.data.push(service);
            })
          }

        }
      }, 500);
      
      if (!inputValue) {
        this.filters.service_slug = null;
        this.$emit("stores-services");
      }
    },
    clickStoreService(event) {
      if (event.item === "store") {
        this.filters.service_slug = null;
        this.$router.push({name:"storeView", params: { slug: event.slug} });
      }
      if (event.item === "service") {
        this.serviceInput = event.name;
        this.filters.service_slug = event.slug;
        this.$emit("stores-services", this.filters, event.name);
      }
    },
    placeSelected(place) {
      this.filters.location = place?.locality || null;
      this.$emit("stores-services", this.filters);
    },
    handleScroll() {
      this.scrollY = scrollY;
    },
    replaceByDefault(event) {
      event.target.src = defaultImage;
    }
  },
  watch: {
    selectedStoreCategory(tab) {
      this.filters.store_category_id = tab.id;
      this.$emit("stores-services", this.filters);
    },
  },
  mounted() {
    if(localStorage.getItem("profilePicture") !== "null"){
      this.profilePicture = localStorage.getItem("profilePicture");
    }else{
      this.profilePicture = JSON.parse(localStorage.getItem("profilePicture"));
    }
  },
  created() {
    addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    removeEventListener("scroll", this.handleScroll);
  },
  components: {
    Card,
    BaseButton,
    DropDown,
    Logo,
    InputPlacesAutocomplete,
    VueTypeaheadBootstrap
  },
};
</script>

<style scoped>
.search-bar-sticky {
  position: sticky;
  top: 0;
  z-index: 100
}
.search-height {
  height: 150px;
  transition: height 0.2s;
}
.search-width {
  max-width: 850px;
  transition: max-width 0.2s;
}
.search-width-100 {
  max-width: 100%;
  transition: max-width 0.2s;
}
.search-input-width {
  max-width: 750px;
}
.logo {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}
.photo {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
}
.dropdown  {
  height: 100px;
}
.profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-custon {
  width: 120px;
}
.margin-end {
  right: 0;
}
.caret {
  position: absolute;
  right: -5%;
  top: 53%;
  margin-left: 0;
  color: black;
}
.font-size {
  font-size: 10px;
}
li.nav-item {
  align-items: center;
  display: flex;
} 
.dropdown .dropdown-menu[data-v-3193f032] {
    transform: translate3d(0, -20px, 0) !important;
}
.categoryItem {
  list-style: none;
  color: white;
  font-weight: 400;
  margin: 0 2rem;
}

@media (min-width: 767px) {
  .search-height {
    height: 100px;
    transition: height 0.2s;
  }
}
@media (max-width: 767px) {
  .search-height-sm {
    height: 150px;
    transition: height 0.2s;
  }
  .margin-bottom {
    margin-bottom: 80px;
  }
}
@media (max-width: 1200px) {
  .search-input-width {
    max-width: 600px;
  }
}
</style>
<style>
/* Change the default width of the typeahead, which is set by the input width. */
.vbt-autcomplete-list {
  width: 50vw !important;
}
.whereInput > input {
  border: none;
  border-bottom: 1px solid;
  border-color: rgba(34, 42, 66, 0.5);
  margin-left: 1rem;
  border-radius: 0;
}
@media (max-width: 800px) {
  .vbt-autcomplete-list {
    width: 80vw !important;
  }
}
</style>
