var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar",class:[
    { 'navbar-expand-lg': _vm.expand },
    { [`navbar-${_vm.effect}`]: _vm.effect },
    { 'navbar-transparent': _vm.transparent },
    { [`bg-${_vm.type}`]: _vm.type },
    { rounded: _vm.round },
  ]},[_vm._t("container-pre"),_c('navbar-toggle-button',{attrs:{"toggled":_vm.toggled,"target":_vm.contentId},nativeOn:{"click":function($event){$event.stopPropagation();_vm.toggled = !_vm.toggled}}}),_vm._t("container-after"),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeMenu),expression:"closeMenu"}],staticClass:"collapse navbar-collapse",class:{ show: _vm.toggled },attrs:{"id":_vm.contentId}},[_c('div',{staticClass:"navbar-collapse-header"},[_vm._t("content-header",null,{"closeMenu":_vm.closeMenu})],2),_vm._t("default",null,{"closeMenu":_vm.closeMenu})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }