<template>
  <div>
    <div>
      <ul class="nav nav-tabs">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          class="nav-item cursor-pointer"
          @click="selectTab(tab)"
        >
          <a
            class="nav-link customiced-tab-link-sm"
            :class="{ active: tab.isActive, 'badge-not-services': withBadge && tab.name === $tc('services', 2) }"
            :disable="tab.isActive"
          >
            {{ tab.name }}
          </a>
        </li>
      </ul>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props:{
    isRoute: {
      default: false,
    },
    withBadge: {
      default: false
    }
  },
  data() {
    return {
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted(){
    if(this.isRoute){
      this.tabs.forEach((tab) => {
          tab.isActive = tab.route === this.$route.name;
      });

    }
  },
  methods: {
    selectTab(selectedTab) {
      if(this.isRoute && !selectedTab.isActive){
        this.$router.push({name: selectedTab.route}).catch(()=>{ console.warn('the redirection was stopped') })
      }
        this.tabs.forEach((tab) => {
          tab.isActive = tab.name === selectedTab.name;
        });
      this.setWhichPopover(selectedTab.name);
    },
    ...mapMutations(["setWhichPopover"]),
  },
};

import {mapMutations} from "vuex";
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.nav {
  border: none;
}
.nav-link {
  border: 1px solid #cccccc80!important;
  border-radius: 0.4285rem 0.4285rem 0 0;
  margin-right: 1px;
}
.badge-not-services::after {
  content: '';
  width: 6px;
  height: 6px;
  position: absolute;
  margin-left: 2px;
  border-radius: 10px;
  background: #ff4e50!important;

  animation: pulse-animation 2s infinite;
}
@media (max-width: 600px) {
  .customiced-tab-link-sm {
    padding: 8px 7px;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
