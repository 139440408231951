<template>
  <carousel
    :perPageCustom="[
      [0, 1],
      [479, 2],
      [1024, 4],
    ]"
    :scrollPerPage="true"
    :pagination-enabled="true"
  >
    <slide v-for="(picture, index) in images" :key="index" class="mx-auto px-2 py-2 min-w">
      <img :src="picture" loading="lazy" class="max-wid-100" alt="" width="300px" height="100%"/>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "TabsCarousel",
  props: ["images"],
  components: {
    Carousel,
    Slide,
  },
};
</script>

<style>
  .max-wid-100{
    max-width: 100%;
  } 
</style>
