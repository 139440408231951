import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const languages = ["es", "en", "de"];

export default new Vuex.Store({
  state: {
    defaultTitle: "AS Saloon",
    adminRole: "Admin",
    managerRole: "Manager",
    employeeRole: "Employee",
    customerRole: "Customer",
    isAdmin: false,
    isManager: false,
    isEmployee: false,
    isCustomer: false,
    isAuthenticated: false,
    routesDashboard: [
      {
        url: "/bookings",
        title: "booking",
        icon: "icon-calendar-60",
        canView: ["Manager", "Employee"],
      },
      {
        url: "/users",
        title: "user",
        icon: "icon-single-02",
        canView: ["Admin"],
      },
      {
        url: "/managers",
        title: "manager",
        icon: "icon-single-02",
        canView: ["Admin"],
      },
      {
        url: "/stores",
        title: "store",
        icon: "icon-square-pin",
        canView: ["Admin"],
      },
      {
        url: "/categories",
        title: "category",
        icon: "icon-components",
        canView: ["Admin"],
      },
      {
        url: "/customers",
        title: "customer",
        icon: "icon-single-02",
        canView: ["Manager", "Employee"],
      },
      {
        url: "/storeManagement",
        title: "storeManagement",
        icon: "icon-square-pin",
        canView: ["Manager"],
      },
      {
        url: "/employees",
        title: "employee",
        icon: "icon-badge",
        canView: ["Manager"],
      },
      {
        url: "/services",
        title: "servicesManagement",
        icon: "icon-paper",
        canView: ["Manager"],
      },
      {
        url: "/statistics",
        title: "statistics",
        icon: "icon-chart-bar-32  º",
        canView: ["Manager", "Admin"],
      },
      {
        url: "/mail",
        title: "mail",
        icon: "icon-email-85",
        canView: ["Admin"],
      },

    ],
    previousPage: "/",
    nextPage: null,
    serviceCategorieControl: "list",
    serviceControl: "list",
    changedProfilePicture: "",
    storeName: null,
    whichPopover: null,
    hasChangeForm: false,
    isOpenModalLeavePage: false,
    locale: languages.includes(navigator.language.slice(0, 2)) ? navigator.language.slice(0, 2) : process.env.VUE_APP_I18N_LOCALE || "es",
    rebookEmployee: null
  },
  mutations: {
    setRole(state, roleString) {
      state.isAdmin = roleString === state.adminRole;
      state.isManager = roleString === state.managerRole;
      state.isEmployee = roleString === state.employeeRole;
      state.isCustomer = roleString === state.customerRole;
    },
    setPreviousPage(state, previousPage) {
      previousPage = previousPage ? previousPage : "/";
      state.previousPage = previousPage;
    },
    setAuthenticate(state, authenticate) {
      state.isAuthenticated = !!authenticate;
    },
    setServiceCategorie(state, listForm) {
      state.serviceCategorieControl = listForm;
    },
    setService(state, listForm) {
      state.serviceControl = listForm;
    },
    setChangedProfilePicture(state, change) {
      state.changedProfilePicture = change;
    },
    setStoreName(state, name) {
      state.storeName = name;
    },
    setWhichPopover(state, name) {
      state.whichPopover = name;
    },
    setHasChangeForm(state, hasChangeForm){
      state.hasChangeForm = hasChangeForm;
    },
    setIsOpenModal(state, isOpenModalLeavePage){
      state.isOpenModalLeavePage = isOpenModalLeavePage;
    },
    setNextPage(state, nextPage){
      state.nextPage = nextPage;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    setEmployeeToRebook(state, employeeUuid) {
      state.rebookEmployee = employeeUuid;
    }
  },
  getters: {
    getWhichPopover: state => {
      return state.whichPopover;
    }
  },
  actions: {
    setAuthenticate( { commit }, value ) {
      commit('setAuthenticate', value)
    },
    setChangedProfilePicture( { commit }, value ) {
      commit('setChangedProfilePicture', value)
    },
    setHasChangeForm( { commit }, value ) {
      commit('setHasChangeForm', value)
    },
    setIsOpenModal( { commit } , value ) {
      commit('setIsOpenModal', value)
    },
    setNextPage( { commit }, value ) {
      commit('setNextPage', value)
    },
    setPreviousPage( { commit }, value ) {
      commit('setPreviousPage', value)
    },
    setRole( { commit }, value) {
      commit('setRole', value)
    },
    setStoreName( { commit }, value ) {
      commit('setStoreName', value)
    },
    setEmployeeToRebook( { commit }, value ) {
      commit('setEmployeeToRebook', value)
    }
  },
  modules: {},
});
