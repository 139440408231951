<template>
  <base-input
    :value="value"
    :placeholder="$t(placeholder)"
    :label="label? `${$t(label)}`: ''"
    id="autocomplete"
    required
    @input="handlerInput"
    v-on:keydown.enter.prevent
  ></base-input>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import { BaseInput } from "@/components/index";

export default {
  name: "input-places-autocomplete",
  props: {
    label: String,
    value: [String, Number],
    placeholder: {
      type: String,
      default: 'address'
    },
  },
  data() {
    return {
      autocompleteInput: null,
    };
  },
  computed: {
    valueComputed: {
        get: function() {
          return this.value
        },
        set: function(value) {
          this.$emit('place-selected', value);
        }
    }
  },
  mounted() {
    this.initApiGoogle();
  },
  methods: {
    initApiGoogle() {
      const loader = new Loader({
        apiKey: "AIzaSyDgv5nDSm_ZYUCKkAsVrPC9CgGYTS7sAMA",
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then((google) => {
        const options = {
          componentRestrictions: { country: "es" },
          fields: ["formatted_address", "geometry", "name", "address_components"],
          strictBounds: false,
        };
        this.autocompleteInput = new google.maps.places.Autocomplete(
          document.getElementById("autocomplete"),
          options
        );
        this.initListener();
      });
    },
    initListener() {
      this.autocompleteInput.addListener("place_changed", this.placeSelected);
    },
    handlerInput(inputValue) {
      inputValue = String(inputValue).trimEnd().trimStart();

      inputValue.length === 0 && this.$emit("place-selected", null);
    },
    placeSelected() {
      const place = this.autocompleteInput.getPlace();
      if (place.formatted_address !== this.value) {
        const payload = {
          address: place.formatted_address,
          lat: place.geometry?.location.lat(),
          lng: place.geometry?.location.lng(),
          country: place.address_components?.find( address => address.types.includes('country'))?.long_name,
          locality: place.address_components?.find( address => address.types.includes('locality'))?.long_name,
          administrative_area_level_1: place.address_components?.find( address => address.types.includes('administrative_area_level_1'))?.long_name,
          administrative_area_level_2: place.address_components?.find( address => address.types.includes('administrative_area_level_2'))?.long_name,
        };

        if(!payload.locality) payload.locality = place.address_components?.find( address => address.types.includes('administrative_area_level_2'))?.long_name;

        this.$emit("place-selected", payload);
      }
    },
  },
  components: {
    BaseInput,
  },
};
</script>

<style></style>
