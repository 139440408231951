<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="cbId" class="form-check-label">
      <input
        :id="cbId"
        class="form-check-input required-error"
        type="checkbox"
        :disabled="disabled"
        v-model="model"
      />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: "base-checkbox",
  model: {
    prop: "checked",
  },
  props: {
    disabled: Boolean,
    inline: Boolean,
    checked: [Boolean, Number],
    id: Number,
  },
  data() {
    return {
      cbId: "",
      touched: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(check) {
        const payload = check;

        if (!this.touched) {
          this.touched = true;
        }
        this.$emit("input", payload);
        this.$emit("change", this.touched);
      },
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return null;
    },
  },
  created() {
    this.cbId = Math.random().toString(16).slice(2);
  },
};
</script>