import axios from "axios";
import store from '../../store/index.js';

const useLogout = async () => {
    const route = "/auth/logout"
    let response = await axios.get(route);
    if (response && response.data.status === "success") {
        localStorage.removeItem("user");
        localStorage.removeItem("api_token");
        localStorage.removeItem("token_expires_in");
        localStorage.removeItem("userRole");
        localStorage.removeItem("profilePicture");
        store.dispatch("setRole", null);

        return {
            ...response
        }
    }
}

export default useLogout;