<template>
  <div class="h-100">
    <v-chart ref="chart" :options="heatmapOptions" autoresize />
  </div>
</template>
  
<script>
  import VueECharts from "vue-echarts";
  import "echarts/lib/chart/heatmap";
  import "echarts/lib/component/tooltip";
  import "echarts/lib/component/axis";
  import "echarts/lib/component/grid";
  import "echarts/lib/component/visualMap";

  export default {
    name: 'TimeHeatMapChart',
    components: { "v-chart": VueECharts },
    props: {
      chartData: {
        // ['hour of day', 'day of week', 'quantity']
        type: Array,
        required: true
      },
      max: {
        type: Number,
        required: true
      },
      min: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        heatmapOptions: {
          tooltip: {
            position: "top",
            formatter: (args) => `
              <span>${args.seriesName}</span>
              <p style="padding:5px 5px 0px;">${args.marker}${args.name}: <b>${args.data.slice(-1)}</b></p>
            `,
          },
          animation: true,
          grid: {
            height: "80%",
            top: "0%",
            tooltip: {
              backgroundColor: '#fff',
              borderColor: '#ccc',
              borderWidth: 1,
              textStyle: {
                color: '#000',
                background: '#fff',
                fontSize: '14',
                fontWeigth: 'bold'
              }
            }
          },
          xAxis: {
            type: "category",
            data: this.setScaleHours(),
            splitArea: { show: true },
          },
          yAxis: {
            type: "category",
            data: this.setScaleDays(),
            splitArea: { show: true },
          },
          visualMap: {
            min: this.min,
            max: this.max,
            calculable: true,
            orient: "horizontal",
            left: "center",
            bottom: "7%"
          },
          series: [
            {
              name: this.$tc('booking', 0),
              type: "heatmap",
              data: this.chartData,
              label: { show: true },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        },
      };
    },
    methods: {
      setScaleDays() {
        const days = [];
        const dayLabels = {
          0: 'monday',
          1: 'tuesday',
          2: 'wednesday',
          3: 'thursday',
          4: 'friday',
          5: 'saturday',
          6: 'sunday'
        };
        
        for (let index = 0; index < 7; index++) {
           
          days.push(this.$t(`days.${dayLabels[index]}`))
        }
        return days;
      },
      setScaleHours() {
        return [ "00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00" ];
      }
    },
  };
  </script>
  
  <style scoped>
    .echarts {
        width: 100% !important;
        height: 100%;
    }
  </style>