<template>
  <div class="mx-5">
        <el-carousel :interval="4000" :type="withCard? 'card shadow': ''" height="300px">
            <el-carousel-item v-for="(slide, i) in slides" :key="i">
            <div class="item">
                <div class="item__content p-sm-5 align-middle">
                    <div class="d-md-flex justify-content-between align-items-center">
                        <h2 class="m-0 mb-2">{{slide.personName}}</h2>
                        <p class="m-0 mb-2">{{slide.storeName}}</p>
                    </div>
                    <p>{{slide.testimonial}}</p>
                </div>
                <img
                  loading="lazy"
                  class="item__image"
                  :src="require(`@/assets/img/${slide.storeImage}`)"
                  alt="slider image"
                  width="100%"
                  height="100%"
                />
            </div>
            </el-carousel-item>
        </el-carousel>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from 'element-ui'

export default {
    props: {
      slides: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        withCard: true
      }
    },
    components: {
      'el-carousel': Carousel,
      'el-carousel-item': CarouselItem
    },
    methods: {
      onResize() { 
        this.withCard = window.innerWidth >= 768;
      }
    },
    created() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
}
</script>

<style>
.item {
  position: relative;
  height: 100%;
  border-radius: 1em;
}

.item__content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 15px;
  overflow: auto;
}

.item__content::-webkit-scrollbar {
  -webkit-appearance: none;
}

.item__content::-webkit-scrollbar:vertical {
  width: 0.4rem;
}

.item__content::-webkit-scrollbar-button:increment,.item__content::-webkit-scrollbar-button {
  display: none;
} 

.item__content::-webkit-scrollbar:horizontal {
  height: 0.6rem;
}

.item__content::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 10px;
}

.item__content::-webkit-scrollbar-track {
  border-radius: 10px;
}

.el-carousel {

  border-radius: 1rem;
}

.item__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1em;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  border-radius: 1em;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  border-radius: 1em;
}
</style>