<template>
  <button
    :is="tag"
    :type="tag === 'button' ? nativeType : ''"
    :disabled="disabled || loading"
    @click="handleClick"
    class="btn shadow-sm"
    :class="[
      { 'btn-round': round },
      { 'btn-block': block },
      { 'btn-icon btn-fab': icon },
      { [`btn-${type}`]: type },
      { [`btn-${size}`]: size },
      { 'btn-simple': simple },
      { 'btn-link': link },
      { disabled: disabled && tag !== 'button' },
    ]"
  >
    <slot name="loading" >
      <em v-if="loading" class="fas fa-spinner fa-spin" style="width:1rem;heigth: 1rem;"></em>
    </slot>
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "base-button",
  props: {
    tag: {
      type: String,
      default: "button",
    },
    nativeType: {
      type: String,
      default: "button",
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    disabled: Boolean,
    loading: Boolean,
    type: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "",
    },
    simple: {
      type: Boolean,
    },
    link: {
      type: Boolean,
    },
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>
<style>
@media (max-width: 600px) {
  button {
    width: 100%;
  }
}
</style>
