<template>
  <div class="card card-plain col col-md-6">
    <div :id="'headingOne' + id + 1" class="card-header pointer" @click="$emit('open-group', {id, active})">
      <a
        data-toggle="collapse"
        :data-target="'#headingOne' + id"
        :aria-expanded="active"
        :aria-controls="`content-${id}`"
        class="d-flex justify-content-between"
      >
        <slot name="title">
          <h2>{{ title }}</h2>
        </slot>
        <em v-if="active" class="tim-icons icon-minimal-up pb-1" ></em>
        <em v-else class="tim-icons icon-minimal-down pb-1"></em>
      </a>
    </div>

    <div
      :id="'headingOne' + id"
      data-parent="#accordionExample"
      role="tabpanel"
      :aria-labelledby="'headingOne' + id + 1"
      class="collapse fade-in"
      :class="{ show: active }"
    >
      <div class="card-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'collapse-item',
  props: {
    title: {
      type: String,
      default: "",
    },
    active: {
      type: Boolean,
      default: false,
    },
    id: Number,
  }
};
</script>
<style scoped>

.card {
  box-shadow: none!important;
}
.pointer {
  cursor: pointer;
}
.fade-in {
	-webkit-animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 0.8s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
