<template>
  <div class="h-100 echarts">
    <v-chart ref="chart" :options="chartOptionsLine" autoresize />
  </div>
</template>
    
<script>
import 'echarts/lib/chart/line';
import 'echarts/lib/component/title';
import 'echarts/lib/component/legend';
  
export default {
  name: 'LineChart',
  data() {
    return {
      chartOptionsLine: {
        tooltip: {
          trigger: 'item',
          axisPointer: { type: 'cross' },
          formatter: (args) => `
            <span>${args.marker}${args.name}</span>
            <br />
            <p style="padding:5px 5px 0px;">${args.data} ${args.seriesName}</p>
          `,
        },
        grid: {
          tooltip: {
            backgroundColor: '#fff',
            borderColor: '#ccc',
            borderWidth: 1,
            textStyle: {
              color: '#000',
              background: '#fff',
              fontSize: '14',
              fontWeigth: 'bold'
            }
          }
        },
        legend: {
          data: this.chartData.titles
        },
        xAxis: {
          data: this.chartData.labels
        },
        yAxis: {
          type: "value"
        },
        series: this.chartData.chartsData.map( ({type, name, data}) => ({type, name, data}) ), 
        color: this.chartData.colors
      }
    }
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({
        labels: [],
        chartsData: [] 
      })
    }
  },
}
</script>

<style scoped>
.echarts {
  width: 100% !important;
  height: 35rem;
}
.tooltipTitle {
  color:red;
}
</style>