<template>
  <Carousel :slides="testimonials"/>
</template>

<script>
import { Carousel } from "@/components/index";

export default {
  name: "ToolsApp",
  data() {
    return {
      testimonials: [
        {
            storeImage: 'image-testimonial-recio-barber-shop.jpg',
            storeName: 'Recio Barber Shop',
            personName: 'Daniel Heredia',
            testimonial: 'Desde que comencé a usar As Saloon en mi peluquería he notado una reduccíon de llamadas de al menos un 50 % en 6 meses, lo que me ha permitido dar un trato más exclusivo a mis clientes sin interrupciones, ahorrándome unos 220 euros al mes. También me ha permitido disfrutar de mi tiempo libre ya que mis clientes pueden reservar a cualquier hora y cualquier día sin necesidad de llamarme a mi directamente. En definitiva he visto en estos 6 meses como mi clientela y la frecuencia con la que viene ha aumentado, así cómo mi eficiencia en el servicio.'
        },
        {
            storeImage: 'image-testimonial-sugar-nails.jpg',
            storeName: 'Sugar Nails',
            personName: 'Silvia Delgado',
            testimonial: 'Lo primero que nos llamó la atención de la aplicación As Saloon fue lo sencilla que es de utilizar incluso sin haber tenido previamente ningún servicio parecido. Además, la atención del equipo de As Saloon ha sido fantástica desde primera hora resolviendo todas nuestras dudas y acompañándonos en el proceso. Hemos observado cómo los clientes vienen con más frecuencia y cómo hemos reducido casi al mínimo el número de interrupciones durante el trabajo y fuera de el, ahorrando significativamente en costes y aumentando la fidelidad y satisfacción de nuestros clientes al darles la facilidad de reservar nuestros servicios cuando quieran y eligiendo a su profesional favorito.'
        }
      ],
    };
  },
  components: {
    Carousel
  },
};
</script>
