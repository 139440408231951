<template>
  <div
    class="form-group"
    :class="{
      'input-group': hasIcon,
      'input-group-focus': focused,
    }"
  >
    <slot name="label">
      <label v-if="label" class="control-label">
        {{ label }}
      </label>
      <Popover textBtn="?" :body="btnHelpDescription" v-if="btnHelp" reason="help"></Popover>
    </slot>
    <slot name="addonLeft">
      <span v-if="addonLeftIcon" class="input-group-prepend">
        <div :class="classes" class="input-group-text">
          <em :class="addonLeftIcon"></em>
        </div>
      </span>
    </slot>
    <slot>
      <input
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
        class="form-control"
        :class="classes"
        :type="inputType"
        aria-describedby="addon-right addon-left"
      />
    </slot>
    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div :class="classes" class="input-group-text iconLogin">
          <em :class="addonRightIcon"></em>
        </div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
import Popover from '../Popover.vue';
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    label: String,
    value: [String, Number],
    addonRightIcon: String,
    addonLeftIcon: String,
    classes: String,
    btnHelp: Boolean,
    btnHelpDescription: String,
    inputType: {
      type: String,
      default: 'text'
    }
  },
  model: {
    prop: "value",
    event: "input",
  },
  data() {
    return {
      focused: false,
      isShowModalHelp: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
      };
    },
  },
  methods: {
    onInput(evt) {
      this.$emit( "input", evt.target.value );
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
  },
  components: {
    Popover,
  },
};
</script>
<style scoped>
.input-rounded {
  border-radius: 1.5rem;
}
.iconLogin {
  padding: 10px 15px!important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
