var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{class:_vm.classes,attrs:{"href":_vm.isAuthenticated
      ? _vm.isManager || _vm.isEmployee
        ? _vm.bookings
        : _vm.isAdmin
        ? _vm.users
        : _vm.home
      : _vm.home}},[_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photo"},[_c('img',{attrs:{"loading":"lazy","src":require("@/assets/img/Logo_AS_Transparent.png"),"alt":"Logo AS Saloon","width":"30px","height":"30px"}})])
}]

export { render, staticRenderFns }